import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation } from '@fortawesome/free-solid-svg-icons';

export default function Warning({ warning }) {
  return (
    <div className='excl'>
        {warning && <FontAwesomeIcon icon={faExclamation} style={{color: 'red', marginLeft: '20px'}} /> }
    </div>
  )
}
