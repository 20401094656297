// Libraries
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
// Utilities
import { useOutsideClick } from '../utilities/helper';
// Styles


export default function BurgerMenu({ menuItems }) {

    const [showDropdown, setShowDropdown] = useState(false);
    const handleClick = () => setShowDropdown(!showDropdown);
    const handleClickOutside = () => setShowDropdown(false);

    const ref = useOutsideClick(handleClickOutside);
    const handleDivClick = (event) => {
      event.stopPropagation();
    };

    const menu = menuItems.map((menuItem, i) => (
        <NavLink to={menuItem.link} className={ ({isActive}) => isActive ? 'ddi sel' : 'ddi' } onClick={()=>setShowDropdown(false)} key={i}>
          <span>{menuItem.title}</span>
          <hr />
        </NavLink>
  ));

  return (
    <div className='burger-menu' onClick={handleDivClick}>
        <button ref={ref} onClick={handleClick}>
          <FontAwesomeIcon icon={faBars} size="2x"/>
        </button>
        <nav id='burger-items' className={showDropdown? 'show' : 'hide'}>
          { menu }
        </nav>
      </div>
  )
}
