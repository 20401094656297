import { query, collection, doc, getDocs, getDoc, setDoc, deleteDoc, where, orderBy } from "firebase/firestore";
import { saveLog } from "./logRepository";
import { db } from "../firebase";

const peopleCollection = 'people';
const peopleRef = collection(db, peopleCollection);

// Gets a list of all people from the people collection
export const getPeople = async function(){
    try {
        const peopleSnapshot = await getDocs(peopleRef)
        let people = [];
        peopleSnapshot.docs.forEach(s => {
            let person = s.data();
            person.id = s.id;
            people.push(person);
        });
        return people;
    }
    catch(err){
        console.error(err.message);
        return [];
    }
}

// Gets a person from the people collection by their id (nickname)
export const getPerson = async function(id){
    try {
        const docRef = doc(db, peopleCollection, id);
        const docSnapshot = await getDoc(docRef);
        return docSnapshot ? docSnapshot.data(): null;    
    }
    catch(err){
        console.error(err.message);
        return null;
    }
}

// Adds /updates a person to the people collection
export const savePerson = async function(person){
    const methodname = 'savePerson';
    try {
        const docRef = doc(db, peopleCollection, person.id);
        await setDoc(docRef, person);   
        saveLog(methodname, peopleCollection, person.id, person);
    }
    catch(err){
        console.error(err.message);
        saveLog(methodname, peopleCollection, person.id, person, err);
    }
}

// Deletes a person from the people collection
export const deletePerson = async function(person){
    const methodname = 'deletePerson';
    try {
        const docRef = doc(db, peopleCollection, person.id);
        await deleteDoc(docRef);
        saveLog(methodname, peopleCollection, person.id, person);
    }
    catch(err){
        console.error(err.message);
        saveLog(methodname, peopleCollection, person.id, person, err);
    }
}

// Adds the plot name (id) to the field 'plots' in the person object
export const addPlotToPerson = async function(person, plot){
    const methodName = 'addPlotToPerson';
    const plotName = plot.id;
    let plotNamesArray = person.plots; 
    if (plotNamesArray.indexOf(plotName) !== -1) return;
    plotNamesArray.push(plotName);
    plotNamesArray.sort((a,b) => a > b ? 1 : -1);
    person.plots = plotNamesArray;
    person.isPlotHolder = true;
    if (plotNamesArray.length === 1){
        person.dateJoined = new Date().toISOString().slice(0,10);
    }
    await saveLog(methodName, peopleCollection, person.id, person);
    await savePerson(person);
} 

// removes the plot name (id) from the field 'plots' in the person object
export const removePlotFromPerson = async function(person, plot){
    const methodName = 'removePlotFromPerson';
    const plotName = plot.id;
    let plotNamesArray = person.plots;
    const i = plotNamesArray.indexOf(plotName);
    if (i === -1) return;
    plotNamesArray.splice(i, 1);
    person.plots = plotNamesArray;
    if (!plotNamesArray.length && person.onWaitingList){
        person.isPlotHolder = false;
    }
    await saveLog(methodName, peopleCollection, person.id, person);
    await savePerson(person);
} 



//
export const switchToWaitingList = async function(person){
    const methodName = "switchToWaitingList";
    if (person.plots.length) {  
        alert("This person has plots");
        return;
    }
    person.onWaitingList = true;
    person.isPlotHolder = false;
    person.joinedWaitingList = new Date().toISOString().slice(0,10);
    await saveLog(methodName, peopleCollection, person.id, person);
    await savePerson(person);
}

//
export const switchToPlotHolders = async function(person){
    const methodName = "switchToPlotHolders";
    if (!person.plots.length) {
        alert("This person doesn't have a plot yet");
        return;
    }
    person.isPlotHolder = true;
    person.onWaitingList = false;
    await saveLog(methodName, peopleCollection, person.id, person);
    await savePerson(person);
}

// Gets all current plotholders - not in use
export const getPlotHolders = async function(){
    try {
        const q = query(peopleRef, where('onWaitingList', '==', false));
        const querySnapshot = await getDocs(q);
        if(querySnapshot.empty){
            return null;
        }
        return querySnapshot.docs.map(d => d.data());  
    }
    catch (err){
        console.error(err.message);
    }
}

// Gets the current waiting list  - not in use
export const getWaitingList = async function(){
    try {
        const q = query(peopleRef, where('onWaitingList', '==', true)
        , orderBy('joinedWaitingList', 'desc'));
        const querySnapshot = await getDocs(q);
        if(querySnapshot.empty){
            return null;
        }   
        return querySnapshot.docs.map(d => d.data());
    }
    catch (err){
        console.log(err.message);
        console.error(err.message);
    }
}

// Finds people by a given plot name = not in use
export const findByPlotName = async function(plotName){
    const people = await getPeople();
    // TODO: this might not work
    return people.filter(p => {
        let plots = p.plots;
        return plots.indexOf(plotName) !== -1;
    });        
}