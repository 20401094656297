// Libraries
import React from 'react';
import { NavLink } from 'react-router-dom';
// Utilities
import { navItems } from '../utilities/helper';
// Components
import BurgerMenu from './BurgerMenu';
// Styles
import '../styles/nav.css';


export default function NavBar({ guest, guestLevel, signOut }) {

    const normalMenu = navItems.filter(item => item.level === 0)
                .map((item, index) => (
                <NavLink to={item.link} key={index} className={ ({isActive}) => isActive ? 'selected' : 'link' }>
                    {item.title}
                </NavLink>
                )
            );

    const burgerItems = navItems.filter(item => item.level > 0 && item.level <= guestLevel)
    
    const logIn = <NavLink to="login" className={ ({isActive}) => isActive ? 'selected' : 'link' }>
                    <button>
                        Login
                    </button>
                </NavLink>;
    const logOut = <NavLink to="/" className='link'>
                    <button onClick={()=>signOut()}>
                        Logout
                    </button>
                </NavLink>;
    const logInOut = guest ? logOut : logIn;

  return (
    <nav className='strip'>
        <div className='nav-left'>
            { normalMenu }
        </div>
        <div className='nav-right'>
            {guestLevel ? <div className='burger-container'>
                <BurgerMenu menuItems={burgerItems} />
            </div> : null}
            <div className='logInOut'>
                { logInOut }
            </div>
        </div>
    </nav>
  )
}
