import { db, storage } from "../firebase";
import { collection, getDocs } from "firebase/firestore";
import { ref, getDownloadURL } from 'firebase/storage';


const landCollection = 'land_documents';
const landRef = collection(db, landCollection);

// Gets all the land document information
const getLandDocs = async function(){
    try {
    const landDocsSnapshot = await getDocs(landRef);
    let landDocs = [];
    for (let item of landDocsSnapshot.docs) {
        let landDoc = item.data();
        landDoc.id = item.id;
        landDoc.url = await getLandUrl(landDoc.path);
        landDocs.push(landDoc);
    }
    return landDocs;
    }
    catch(err){
        alert(err.message);
    }
}

// Fetches the URL of a document
const getLandUrl = async function(path){
    const pathRef = ref(storage, path);
    return getDownloadURL(pathRef);
}

export { getLandDocs };