// Libraries
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
// Utilities
import { convertTimeStamp } from '../../utilities/helper';
// Components
import Warning from '../bits/Warning';


export default function LogList( {logs } ) {

    const navigate = useNavigate();

    const listItem = (l, i) => {
        let hasError = l.error !== 'null';
        let errorLink = `${l.id}/error`;
        return (
        <li className='log-list-item' key={i}>
            <div className='log-column w2' onClick={()=>navigate(l.id)}>
                { convertTimeStamp(l.timeStamp) }
            </div>
            <div className='log-column w3' onClick={()=>navigate(l.id)}>
                { l.methodName }
            </div>
            <div className='log-column w2' onClick={()=>navigate(l.id)}>
                { l.userName }
            </div>
            <div className='log-column w1'>
                {hasError && <Link to={errorLink}>
                    <Warning warning={true} />
                </Link>}
            </div>
        </li>
    );
    }

    const logList = logs.map((log, i) => listItem(log, i));

return (
    <ul className='log-list'>
        { logList }
    </ul>
  )
}
