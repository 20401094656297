// Libraries
import React, { useState } from 'react';
// Utilities
// Components
import LandItem from './LandItem';
// Styling

export default function LandList({ landDocs }) {

    const [selectedIndex, setSelectedIndex] = useState(-1);

    const isSelected= (index) => index === selectedIndex;
    const getClassName = (index) => isSelected(index) ? 'land-doc-description-expanded'
                                                : 'land-doc-description';
    const handleClick = (index) => {
        const newIndex = selectedIndex === index ? -1 : index; 
        setSelectedIndex(newIndex);
    }

    const docList = landDocs.map((landDoc, index) => (        
            <div className={getClassName(index)} 
                    key={index}
                    onClick={()=>handleClick(index)}>
                <LandItem landDoc={landDoc} 
                        isSelected={isSelected(index)}
                        setSelectedIndex={setSelectedIndex} />
            </div>
        ));

    return (
        <div className='land-docs-container'>
            { docList }
        </div>
    )
}