import React from 'react';

export default function TryAgain({ setSuccess }) {
  return (
        <h3>
            Something went wrong.  Click&nbsp; 
            <a onClick={()=>setSuccess(null)}>
                here&nbsp; 
            </a> 
            to try again.
        </h3>
  )
}
