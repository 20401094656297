import { collection, doc, getDocs, setDoc, 
    //getDoc, addDoc,  deleteDoc, where, orderBy 
} 
    from "firebase/firestore";
import { db } from "../firebase";
import { saveLog } from "./logRepository";
import { savePerson } from "./peopleRepository";

const plotCollection = 'plots';
const plotRef = collection(db, plotCollection);

export const getPlots = async function() {
    try {
        const plotsSnapshot = await getDocs(plotRef)
        let plots = [];
        plotsSnapshot.docs.forEach(s => {
            let plot = s.data();
            plot.id = s.id;
            plots.push(plot);
        });
        return plots;
    }
    catch(err){
        console.error(err.message);
        return [];
    }
}

export const savePlot = async function(plot, logOperation = true) {
    const methodName = 'savePlot';
    try {
            const docRef = doc(db, plotCollection, plot.id);
            await setDoc(docRef, plot);
            if (logOperation){
                saveLog(methodName, plotCollection, plot.id, plot);
            }
        }
    catch(err){
        console.error(err.message);
        saveLog(methodName, plotCollection, plot.id, plot, err);
    }
}

export const removeAllPlotsFromPerson = async function(person){
    const methodName = 'removePlotsFromPerson';
    let plots = await getPlots();
    const id = person.id;
    let plotNamesArray = person.plots;
    plotNamesArray.forEach(pn => {
        let plot = plots.find(p => p.id === pn);
        const i = plot.tenants.findIndex(t => t === id);
        if (i !== -1){
            plot.tenants.splice(i, 1);
            plot.occupied = plot.tenants.length > 0;
            savePlot(plot);
        }
    });
    person.plots = []; 
    await saveLog(methodName, plotCollection, `${plotNamesArray.length} ids`);
    savePerson(person);
}

export const resetPayments = async function(){
    let plots = await getPlots();
    for (let plot of plots){
        plot.paid = false;
        await savePlot(plot, false);
    }
}