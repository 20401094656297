import React from 'react';
import { useParams, useOutletContext } from 'react-router-dom';
import JSONViewer from 'react-json-viewer';


export default function Log() {
    const { id } = useParams();
    const context = useOutletContext();
    const logs = context.logs;

    const log = logs.find(l => l.id === id);
    let json = JSON.parse(log.value);
    if (!json){
      json = {};
    }

  return (
    <div>
        <JSONViewer json={json} />
    </div>
  )
}
