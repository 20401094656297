// Libraries
import React, { useState, useEffect } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import { orderAlphabetically } from '../../utilities/helper';
// Utilities
// Components
import Loading from '../Loading';
import AddTenants from './AddTenants';
// Styles

export default function AddTenantsHome() {

    const context = useOutletContext();
    const plots = context.plots;
    const people = context.people;
    const { id } = useParams();
    const [startingPeople, setStartingPeople] = useState(people);
    const [tenants, setTenants] = useState(null);
    const [plot, setPlot] = useState(null);

    useEffect(() => initialise(), [plot])
    useEffect(() => {
        const thisPlot = getPlot();
        const tenantIds = thisPlot.tenants;
        let tempTenants = tenantIds.map(id => findPersonById(id));
        orderAlphabetically(tempTenants, 'lastName');
        setTenants(tempTenants);
    }, []);

    const findPersonById = function(id){
        if (!people) {
            console.log(`People is ${people}`);
            return null;
        }
        return people.find(p => p.id === id);
    }

    const removeTenantFromStartingPeople = (startingList, t) => {       
        if (startingList && t) { 
            const i = startingList.findIndex(p => p.id === t.id);
            if (i !== -1){
                startingList.splice(i,1);
            }
        }
        return startingList;
    }

    const getPlot = () => {
        const thisPlot = plots.find(p => p.id === id);   
        setPlot(thisPlot);
        return thisPlot;
    }

    const initialise = () => {
        if (!plot) return;
        const tempStartingList = people;
        const tenantIds = plot.tenants;
        let tempTenants = tenantIds.map(id => findPersonById(id));
        tempTenants.forEach(t => {
            removeTenantFromStartingPeople(tempStartingList, t);
        });
        orderAlphabetically(tempStartingList, 'lastName');
        setStartingPeople(tempStartingList);      
    }

    if (!tenants || !plot || !startingPeople ||!people){
        return <Loading />
    }

  return (
    <div className='add-tenants-home-container'>
        <h2>
            Add / remove tenants from {plot.id}
        </h2>
        <div className='add-tenants-container'>
            <AddTenants plotTenants={tenants} people={people} startingPeople={startingPeople} plot={plot} />
        </div>
    </div>
  )
}
