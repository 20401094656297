// Libraries
import React, { useState, useEffect } from 'react';
import { useOutletContext, useNavigate } from 'react-router-dom';
// Utilities
import { stringStartsWith, recentlyFilled, failed } from '../../utilities/helper';
// Components
import PlotList from './PlotList';
import Map from '../mapFiles/Map2';
import Loading from '../Loading';
// Styles
import '../../styles/plot.css';


export default function PlotsContainer() {

    const context = useOutletContext();
    const navigate = useNavigate();
    const plots = context.plots;
    const [view, setView] = useState(localStorage.getItem('plotsView') ? localStorage.getItem('plotsView') : 'grid');
    const [filterText, setFilterText] = useState("");
    const [highlighted, setHighlighted] = useState([]);

    useEffect(() => applyFilter(), [filterText]);

    const applyFilter = () => {
        if (filterText.length < 2) {
            setHighlighted([]);
            return;
        };
        let filterFn;
        switch (filterText.toLowerCase()){
            case 'empty':
                filterFn = (p) => !p.occupied;
                break;
            case 'paid':
                filterFn = (p) => p.occupied && p.paid;
                break;
            case 'unpaid':
                filterFn = (p) => p.occupied && !p.paid;
                break;
            case 'new':
                filterFn = (p) => recentlyFilled.includes(p.id);
                break;
            case 'failed':
                filterFn = (p) => failed.includes(p.id) && p.occupied;
                break;
            case 'lapsed':
                filterFn = (p) => failed.includes(p.id) && p.occupied && !p.paid;
                break;
            default:
                filterFn = (p) => p.tenants && p.tenants.some(t => 
                    stringStartsWith(t, filterText));  
        }
        let foundPlots = [];
        plots.forEach(p => filterFn(p) ? foundPlots.push(p.id): null);
        setHighlighted(foundPlots);
    }

    const changeView = () => {
        let newView = view === 'grid' ? 'map' : 'grid';
        setView(newView);
        localStorage.setItem('plotsView', newView);
    }

    const buttonText = () => view === 'grid' ? 'Switch to map' : 'Switch to grid'

    const onClick =(e) => (navigate(e.target.id));

    const onTextChange = (e) => setFilterText(e.target.value);

    if (!view) {
        return <Loading />
    }

  return (
    <div className='plots-container'>  
        <div className='view-select'>
            <button onClick={changeView}>
                { buttonText() }
            </button>
        </div>
        <div className='plots-filter'>
            <input type='text' value={filterText} placeholder="Plot holder nickname" onChange={onTextChange} />
        </div>

        { (view === 'grid') && 
        <PlotList plots={plots.filter(p => p.showOnGrid !== false)}  
        highlighted={highlighted.filter(p => p.showOnGrid !== false)} /> }
        <div className='map-container'>
            { (view === 'map') && <Map highlighted={highlighted} onClick={onClick} showLabels={true} /> }
        </div>
    </div>
  )
}
