import { labelConstants } from "./labelConstants";

const Labels = () => {

    const charWidth = 1.6;
    const centrex = (l) => {
        const wordLength = l.length;
        const result = (labelConstants[l].x + wordLength * charWidth / 2).toString();
        return result;
    }  

    const centrey = (l) => (labelConstants[l].y - 0.9).toString();

    const keys = Object.keys(labelConstants);

    const labelComponents = keys.map((l, i) => {                
            let p = labelConstants[l];
            return (
                <g key={i}>
                    <ellipse className="ellipse" 
                            cx={centrex(l)}
                            cy={centrey(l)}
                            rx={(l.length * 6/5).toString()}
                            ry="2.3" />
                    <text className='label'>
                        <tspan x={p.x.toString()} y={p.y.toString()}>
                            { l }
                        </tspan>
                    </text>
                </g>
                )    
            });
    return (
        <>
            { labelComponents }
        </>
    )
}

export default Labels;

