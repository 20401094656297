// Libraries
import React from 'react';
import { useParams, useOutletContext, useNavigate, Link } from 'react-router-dom';
// Utilities

// Components
import Back from '../bits/Back';
// Styles
import '../../styles/plot.css';


export default function Plot() {

    const { id } = useParams();
    const context = useOutletContext();
    const navigate = useNavigate();
    const plots = context.plots;
    const guestLevel = context.guestLevel;
    let plot = null;

    if (plots){
    plot = plots.find(p => p.id === id);
    }

    const editTenants = () => navigate('addTenants', {replace: true});
    const linkActive = (level) => level > 2 ? '' : 'inactive';

    const plotTenantList = (!plot || !plot.tenants) ? []
        : plot.tenants.map((tenantId, i) => 
                <Link key={i} className={'h-list ' + linkActive(guestLevel)} to={`/members/plotholders/${tenantId}`}>{ tenantId }</Link>);

    if (!plot) return <div>Error!</div>

    return (
        <div className='plot-container'>
            <div className='form-container'>
                <div className='form'>
                    <header className='form-header'>{plot.id}</header>
                    <div className='form-group'>
                        <label>Id</label>
                        <input type='text' disabled={true} className="form-input" name='id' value={plot.id}  />
                    </div>
                    <div className='form-group'>
                        <label>Description</label>
                        <input type='text' disabled={true} className="form-input" name='description' value={plot.description}  />
                    </div>
                    <div className='form-group'>
                        <label>Area (m&#178;)</label>
                        <input type='number' disabled={true} className="form-input" name='area' value={plot.area}  />
                    </div>
                    <div className='form-group'>
                        <label>Occupied</label>
                        <div className='cb-container'>
                            <input type='checkbox' disabled={true} className="form-input" name='occupied' checked={plot.occupied} />
                        </div>
                    </div>
                    <div className='form-group'>
                        <label>Distance (m)</label>
                        <input type='number' disabled={true} className="form-input" name='distance' value={plot.distance}  />
                    </div>
                    <div className='form-group'>
                        <label>Gradient</label>
                        <input type='text' className="form-input" disabled={true} name='gradient' value={plot.gradient} />
                    </div>
                    <div className='form-group'>
                        <label>Fee</label>
                        <input type='number' className="form-input" disabled={true} name='fee' value={plot.fee}  ></input>
                    </div>
                    <div className='form-group'>
                        <label>Tenants</label>
                        { plotTenantList }
                        {/* <input type='text' className="form-input" disabled={true} name='fee' value={plot.tenants.join(', ')}  ></input> */}
                        {/* // change */}
                    </div>
                </div>
                <div className='two-button-container'>
                    <Back goTo={-1} />
                    { guestLevel > 2 && <button className='edit' onClick={editTenants}>Edit Tenants</button> }     
                </div>
            </div>      
        </div>
    )
}
