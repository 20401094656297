// Libraries
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
//Utilities
import { savePerson } from '../../utilities/peopleRepository';
import { stringIsEmpty } from '../../utilities/helper';
//Components
import PersonForm from './PersonForm';


export default function PersonDetails({ formPerson, waitingList }) {

    const [finalPerson, setFinalPerson] = useState(null); 
    const [message, setMessage] = useState("");
    useEffect(() => {
        setFinalPerson(formPerson, formPerson.id);
    }, [formPerson]);

    const navigate = useNavigate();

    const getHeading = () => {
        return `Person: ${formPerson.firstName}`;
    }

    const onSubmit = (personModel) => {
        if (stringIsEmpty(personModel.firstName) || stringIsEmpty(personModel.lastName)){
            setMessage(`The person's name must be filled in!`);
            return;
        }
        savePerson(personModel)
        .then(() => {
        navigate(0);
        });
    }

    if (!finalPerson){
        return;
    }

  return (
    <div className='form-container'>
        <div className='form'>
            <header className='form-header'>{getHeading()}</header>
            <PersonForm 
            initialPerson={formPerson} waitingList={waitingList} blank={false} onSubmit={onSubmit} />         
        </div>
        <div className='message'>
            { message }
        </div>
    </div>
  )
}
