// Libraries
import React from 'react';
// Utilities
// Components
import Pdf from '../bits/Pdf';

// Styling

export default function LandSubItem({ landDoc }) {

        return (
            <div>
                <div className='sub-item w2'>
                    { landDoc.name }
                </div>
                <Pdf url={landDoc.url} />
            </div>
        )
    
}