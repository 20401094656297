import { plotPaths, willowPaths, pathPaths, buildingPaths, bayPaths, otherPaths} from './paths.js';
import SvgComponent from './SvgComponent.js';
import PlotNumbers from './PlotNumbers.js';
import Labels from './Labels.js';
import '../../styles/map.css';

export default function Map({ onClick, highlighted, showLabels }) {

    return (
        <svg width="1024" viewBox="0 0 297 210" version="1.1" id="map">
            <SvgComponent className='plot' pathsObject={plotPaths} highlighted={highlighted} onClick={onClick}/>
            <SvgComponent className='willow' pathsObject={willowPaths} />
            <SvgComponent className='path' pathsObject={pathPaths} />
            <SvgComponent className='building' pathsObject={buildingPaths} />
            <SvgComponent className='bay' pathsObject={bayPaths} />
            <SvgComponent className='other' pathsObject={otherPaths} />
            {showLabels && <PlotNumbers />}
            {showLabels && <Labels />}

        </svg>
    );
}