import { numberConstants } from "./numberConstants";

const PlotNumbers = () => {

    const centrex = (p) => (numberConstants[p].x + 0.9 + 0.5 * (label(p).length -1)).toString();
    const centrey = (p) => (numberConstants[p].y - 0.9).toString();
    const radiusx = (p) => (2.4 + 0.5 * (label(p).length -1)).toString();
    const label = (p) => {
        const plotNumber = parseInt(p.slice(4));
        const suffix = p.slice(-1) === 'b' ? 'b' : '';
        return `${plotNumber}${suffix}`;
    };

    const numberComponents = Object.keys(numberConstants).map((p, i) => ( 
            <g key={i}>
                <ellipse className="circle" 
                        cx={centrex(p, i)}
                        cy={centrey(p)}
                        rx={radiusx(p)}
                        ry="2" />
                <text className='number'>
                    <tspan x={numberConstants[p].x.toString()} y={numberConstants[p].y.toString()}>
                        { label(p) }
                    </tspan>
                </text>
            </g>
        ));

    return (
        <>
            { numberComponents }
        </>
    )
}

export default PlotNumbers;

