import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../../styles/contact.css';

export default function Submitted() {
    const navigate = useNavigate();
  return (
    <div className='contact-submitted'>
        <h3>
            Your enquiry has been submitted.
        </h3>
        <button onClick={()=>navigate('/')}>
            Return to home page
        </button>
    </div>
  )
}
