export default function({ pathsObject, className, onClick, highlighted }){

    const plotClass = (plotId) => {
        if (!highlighted || !highlighted.length) {
            return className;
            }
        return highlighted.indexOf(plotId) !== -1 ? "highlighted" : className;
        }

return Object.keys(pathsObject).map((p, i) => 
        <g key={i} onClick={onClick}>
            <path className={plotClass(p)} id={p} d={pathsObject[p]}>
                <title>{p}</title>
            </path>
        </g>
        );
}