// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCrDT5k99wPv8QcuNbJVUV0pU4omGyZ5fU",
  authDomain: "lambhillallotments-5d4c1.firebaseapp.com",
  projectId: "lambhillallotments-5d4c1",
  storageBucket: "lambhillallotments-5d4c1.appspot.com",
  messagingSenderId: "901017444170",
  appId: "1:901017444170:web:77f5fe77171c929c68ebb3",
  measurementId: "G-LP5HDLQVWB"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { auth, db, storage
    //, analytics 
};