import { useRef, useEffect } from "react";

export const storeGuest = (guest) => {
    localStorage.setItem('guest', JSON.stringify(guest));
    }

export const getData = () => {
    const jsonGuest = localStorage.getItem('guest');
    const guest = JSON.parse(jsonGuest);
    const guestLevel = guest ? guest.level : 0;
    return {guest, guestLevel};
    }

export const navItems = [
    {title: 'Home', link: '/', level: 0},
    {title: 'About', link: '/about', level: 0},
    {title: 'News', link: '/news', level: 0},
    {title: 'Contact', link: '/contact', level: 0},
    {title: 'Map', link: '/members/map', level: 1},
    {title: 'Plots', link: '/members/plots', level: 2},
    {title: 'Plotholders', link: '/members/plotholders', level: 3},
    {title: 'WaitingList', link: '/members/waitinglist', level: 3},
    {title: 'Land Info', link: '/members/land', level: 2},
    {title: 'Logs', link: '/members/logs', level: 4}
];

export const getEmptyPerson = (onWaitingList) => ({ 
    firstName: "", 
    lastName: "", 
    id: "",
    dateJoined: new Date().toISOString().slice(0,10),
    address: "",
    phoneNumber: "",
    email: "",
    altEmail: "", 
    onWaitingList,
    isPlotHolder: !onWaitingList,
    joinedWaitingList: new Date().toISOString().slice(0,10),
    plots: []
});

export const getEmptyPlot = () => {
    return ({
        id: "",
        description: "",
        area: 100,
        distance: 0,
        gradient: "flat",
        occupied: true,
        fee: 45,
        tenants: []
    });
}

export const blankContactForm = {
    name: "",
    email: "",
    enquiry: ""
}

export const stringStartsWith = function(mainString, subString){
    if (!subString){
        return true;
    }
    const l = subString.length;
    return mainString.slice(0, l).toLowerCase() === subString.toLowerCase();
}

export const orderAlphabetically = function(objectArray, stringProperty, asc=true){
    const sp = stringProperty;
    const u = asc ? 1 : -1;
    return objectArray.sort((a,b) => a[sp] > b[sp] ? u : -u);
}

export const orderLogsByError = function(logsArray, asc=true){
    const u = asc ? 1 : -1;
    const error = 'error';
    const process = (e) => e === 'null' ? 'false' : 'true'; 
    return logsArray.sort((a,b) => process(a[error]) > process(b[error]) ? u : -u);
}

export const convertTimeStamp = function(timeStamp) {
    const isoString = timeStamp.toDate().toISOString();
    const date = isoString.slice(0,10);
    const time = isoString.slice(12,19);
    return `${date} ${time}`;
}

export const stringIsEmpty = function(str) {
    let strCopy = str;
    return strCopy.trim() === ''; 
}

export const useOutsideClick = (callBack) => {
    const ref = useRef();
    useEffect(() => {
      const handleClick = (event) => {
        if (ref.current && !ref.current.contains(event.target)){
          callBack();
        }
      };
      document.addEventListener('click', handleClick);
      return () => { document.removeEventListener('click', handleClick);
    };
  }, [ref]);
  return ref;
  };

export const convertAndOrderList = (objList, propertyName) => {
    let convertedList = []; 
    for (let obj of objList){
        let i = convertedList.findIndex(el => el[0][propertyName] === obj[propertyName])
        // If the object's property has previously been found
        if (i  > -1){
            convertedList[i].push(obj);
        }
        else {
            convertedList.push([obj]);
        }
    }
    // Order alphabetically 
    return convertedList.sort((a,b) => a[0][propertyName] > b[0][propertyName] ? 1 : -1);
};

export const recentlyFilled = [ 'plot03', 
    'plot15', 
    'plot20', 
    'plot22', 
    'plot24', 
    'plot26', 
    'plot27', 
    'plot28',
    'plot29', 
    'plot30', 
    'plot34', 
    'plot41', 
    'plot50', 
    'plot51', 
    'plot52', 
    'plot66', 
    'plot69', 
    'plot70'
];

export const failed = [
    'plot01', 
    'plot06', 
    'plot23', 
    'plot24', 
    'plot30',
    'plot42', 
    'plot53', 
    'plot57', 
    'plot58',
    'plot59',
    'plot65',
    'plot67',
    'plot68'
];

export const isValidEmail = (email) => email.toLowerCase()
                                            .match(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                            );