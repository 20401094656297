import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';

export default function Pdf({ url }) {
  return (
    <a href={url} target='_blank' className='pdf'>
        <FontAwesomeIcon icon={faFilePdf} />
    </a>
  )
}
