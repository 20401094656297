// Libraries
// Utilities
// Components
import LandSubItem from './LandSubItem';
// Styling

export default function LandSubList({ subDocs }) {

    const docSubList = subDocs.map((landDoc, index) => (        
            <div className='land-sub-item' key={index}>
                <LandSubItem landDoc={landDoc} />
            </div>
        ));

    return (
        <div className='land-sub-docs-container'>
            { docSubList }
        </div>
    )
}