// Libraries
import React from 'react';
import LandSubList from './LandSubList';
// Utilities
import { orderAlphabetically } from '../../utilities/helper';
// Components

// Styling

export default function LandItem({ landDoc, isSelected }) {

        let dateDescription = landDoc[0].dateDescription.split(';');
        let date = new Date(dateDescription[0]).toDateString().slice(4);
        let title = dateDescription[1];
        let pdfCount = landDoc.length;
        const plural = (num) => num === 1 ? '' : 's';
        return (
            <>
                <h2>
                    { date }
                </h2>
                <h3>
                    { title }
                </h3>
                { !isSelected && <>
                ({ pdfCount } document{plural(pdfCount)})
                </>
                }
                {
                    isSelected && <LandSubList subDocs={orderAlphabetically(landDoc, 'sortOrder')}/>
                }
            </>
        )
    
}