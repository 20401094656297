import React from 'react';
import Map from '../components/mapFiles/Map2';

export default function MapPage() {
  return (    
    <div className='plots-container'>  
      <h1>Allotments Map</h1>
      <div className='map-container2'>
        <Map highlighted={[]} onClick={()=>{}} showLabels={true} />
      </div>
    </div>
  )
}
