// Libraries
import React, { useState, useEffect } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
// Utilities
import { getLogsAndCount } from '../utilities/logRepository';
import { orderAlphabetically, orderLogsByError } from '../utilities/helper';
// Components
import LogList from '../components/members/LogList';
import Loading from '../components/Loading';
// Styles
import '../styles/logs.css';


export default function Logs({ guestLevel }) {

    const navigate = useNavigate();
    const [count, setCount] = useState(0);
    const [logs, setLogs] = useState(null);
    const [orderBy, setOrderBy] = useState({ value: 'timeStamp', asc: false });

    useEffect(() => {   
        if (guestLevel < 4){
            navigate('');
        }
        refreshLogs();
    }, []);

    useEffect(() => orderLogs(), [orderBy]);
    // useEffect(() => {if(logs) console.log(logs[0])}, [logs]);

    const refreshLogs = () => {
        getLogsAndCount()
            .then(data => {
                let tempLogs = data.logs;
                orderAlphabetically(tempLogs, 'timeStamp', false); 
                setLogs(tempLogs);
                setCount(data.count);
            });
    }

    const orderLogs = () => {
        if (!logs) return;
        let tempLogs = [...logs];
        if (orderBy.value === 'error'){
            orderLogsByError(tempLogs, orderBy.asc);
        }
        else {
            orderAlphabetically(tempLogs, orderBy.value, orderBy.asc); 
        }
        setLogs(tempLogs);
    }

    const onClick = (e) => {
        const value = e.target.id;
        let asc = value !== 'timeStamp';
        if (value === orderBy.value){
            asc = !orderBy.asc;
        }
        setOrderBy({ value, asc });
    }


    if (!logs){
        return <Loading />
    }
  return (
    <div>
        <div className='log-value-container'>
            <Outlet context = {{ logs }}/>
        </div>
        <div style={{marginLeft: '10px', fontWeight: 'bold', fontSize: '18px'}}>
            Log Count:&nbsp;&nbsp;&nbsp; { count }
        </div>
        <div className='th'>
            <div className='log-column w2' id='timeStamp' onClick={onClick}>
                Time Stamp
            </div>
            <div className='log-column w3' id='methodName' onClick={onClick}>
                Method Name
            </div>
            <div className='log-column w2' id='userName' onClick={onClick}>
                User Name
            </div>
            <div className='log-column w1' id='error' onClick={onClick}>
                Error
            </div>
        </div>
        <LogList logs={logs} />
    </div>
  )
}
