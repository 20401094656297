import React, { useState, useEffect, useRef } from 'react';
import emailjs from '@emailjs/browser';
import { service, template, key } from '../../config';
import { blankContactForm, isValidEmail } from '../../utilities/helper';
import '../../styles/contact.css';

export default function ContactForm({ setSuccess }) {

  const [contactForm, setContactForm] = useState(blankContactForm);
  const [error, setError] = useState(false);
  const form = useRef();
  useEffect(() => setError(hasError(contactForm.email)),[contactForm]);
  
  const hasError = (email) => email && !isValidEmail(email);
  const onChange = (e) => {
    let currentForm = {...contactForm};
    currentForm[e.target.name] = e.target.value;
    setContactForm(currentForm);
  }
  const onSubmit = (e) => {
    e.preventDefault();
    if (!isValidEmail(contactForm.email)){
      setError(true);
      return;
    }
    console.log(contactForm);
    sendEmail();
  }

  const sendEmail = () => {
    emailjs
      .sendForm(service, template, form.current, {
        publicKey: key
      })
      .then(
        () => {
          console.log('SUCCESS!');
          setSuccess(true);
        },
        (error) => {
          console.log('FAILED...', error.text);
          setSuccess(false);
        },
      );
  }

  const errorMessage = 'Please enter a valid email address!';

  return (
    <div className='contact-form-container'>
      <p className='contact-subheading'>Please fill in the forrm below and we will get back to you as soon we can.</p>
      <form ref={form} className='contact-form' onSubmit={onSubmit}>
        <div className='contact-group'>
          <label className='contact-label'>
            Name:
          </label>
          <input type="text" className='contact-element' name='name' required onChange={onChange} />
        </div>
        <div className='contact-group'>
          <label className='contact-label'>
            E-mail Address:
          </label>
          <input type="text" className='contact-element' name='email' required onChange={onChange} />
        </div>
        {error &&
        <div className='contact-group'>
          <label></label>
          <div className='contact-error'>{errorMessage}</div>
        </div>
        }
        <div className='contact-group'>
          <label className='contact-label'>
            Your Enquiry:
          </label>
          <textarea className='contact-element'rows={15} name='enquiry' required onChange={onChange} />
        </div>
        <div id='contact-submit'>
          <button type='submit' className='contact-submit' >
            Send
          </button>
        </div>
      </form>
    </div>
  )
}
