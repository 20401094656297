import React, { useState } from 'react';
import '../styles/contact.css';
import ContactForm from './contact/ContactForm';
import Submitted from './contact/Submitted';
import TryAgain from './contact/TryAgain';

export default function Contact() {
  const [success, setSuccess] = useState(null);
  const Header = () => <h1>Contact Us</h1>
  return (
      <div className='contact-centre'>
        <Header />
        { success == null ?
        <ContactForm setSuccess={setSuccess} /> :
          success ?
        <Submitted /> :
        <TryAgain setSuccess={setSuccess} />
        }
    </div>
  )
}
