import { collection, getDocs, addDoc
    //, doc, getDoc, updateDoc, deleteDoc, where, setDoc 
} from "firebase/firestore";
import { db } from "../firebase";


const logsCollection = 'logs';
const logsRef = collection(db, logsCollection);

const saveLog = async function(methodName, eventCollection, docId, value = null, error = null){
    const jsonGuest = localStorage.getItem('guest');
    const guest = JSON.parse(jsonGuest);
    const userName = guest.userName;
    let log = {
        timeStamp: new Date(),
        userName,
        methodName,
        eventCollection,
        docId,
        value: JSON.stringify(value),
        error: JSON.stringify(error)
    }
    await addDoc(logsRef, log);
}

const getLogsAndCount = async function(){
    const logsSnapshot = await getDocs(logsRef);
    let logs = [];
        logsSnapshot.docs.forEach(s => {
            let log = s.data();
            log.id = s.id;
            logs.push(log);
        });
        return { logs, count: logs.length };
}

export { saveLog, getLogsAndCount };