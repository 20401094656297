// Libraries
import React from 'react';
import { Link } from 'react-router-dom';
// Utilities
// Components
// Styles
import '../../styles/people.css';
import '../../styles/list.css';


export default function PlotList({ plots, highlighted }) {

    const plotClass = (plot) => {
        if (!highlighted || !highlighted.length) {
              return "plot-list-item";
        }
        return highlighted.indexOf(plot.id) !== -1 ? "plot-list-item gold" : "plot-list-item";
  }

    const listItem = (plot, index) => (
        <li className={plotClass(plot)} key={index}>
            <Link className='plot-link' to={plot.id}>
                {plot.id}
            </Link>
        </li>
    );

    let plotList = [];
    if (plots){
        plotList = plots.map((p, i) => listItem(p, i));
    }

  return (
    <div className='plot-list-container'>  
        <ul className='plot-list'>
            { plotList }
        </ul>
    </div>
  )
}
