export const numberConstants = {
    plot01: {x: 40.8, y: 50.4},
    plot02: {x: 52.2, y: 50.7},
    plot03: {x: 62.7, y: 51},
    plot04: {x: 73, y: 51.3},
    plot05: {x: 83, y: 51.6},
    plot06: {x: 91.5, y: 51.9},
    plot07: {x: 100.5, y: 52.2},
    plot08: {x: 109.5, y: 52.5},
    plot09: {x: 116.5, y: 52.8},
    plot10: {x: 123.5, y: 53.1},
    plot11: {x: 132.2, y: 53.4},
    plot12: {x: 141, y: 53.7},
    plot13: {x: 150.5, y: 54.0},
    plot14: {x: 160.2, y: 54.3},
    plot15: {x: 169.3, y: 54.7},
    plot16: {x: 179, y: 55},
    plot17: {x: 189.2, y: 55.3},
    plot18: {x: 199.4, y: 55.6},
    plot19: {x: 209.3, y: 55.9},
    plot20: {x: 218.3, y: 56.2},
    plot21: {x: 227.3, y: 56.5},
    plot22: {x: 237, y: 56.8},
    plot23: {x: 247, y: 57.1},
    plot24: {x: 257.2, y: 57.4},
    plot26: {x: 254, y: 90},
    plot27: {x: 243, y: 91},
    plot28: {x: 233, y: 92},
    plot29: {x: 223.7, y: 92.9},
    plot30: {x: 213, y: 93.7},
    plot31: {x: 203.3, y: 94.7},
    plot32: {x: 194, y: 96},
    plot33: {x: 183.5, y: 96.8},
    plot34: {x: 173.8, y: 98.1},
    plot35: {x: 164.3, y: 98.6},
    plot37: {x: 140.6, y: 100.5},
    plot38: {x: 132.6, y: 101.3},
    plot39: {x: 123.3, y: 102.3},
    plot40: {x: 114.3, y: 102.7},
    plot41: {x: 105.7, y: 102.2},
    plot42: {x: 96.5, y: 102.2},
    plot43: {x: 88.4, y: 101.4},
    plot44: {x: 79.5, y: 101.3},
    plot45: {x: 68.4, y: 101.1},
    plot49: {x: 31.8, y: 147.3},
    plot50: {x: 40.7, y: 147.8},
    plot51: {x: 48.9, y: 147.8},
    plot52: {x: 55.8, y: 147.6},
    plot53: {x: 64.7, y: 146.9},
    plot54: {x: 73.8, y: 145.9},
    plot55: {x: 82.4, y: 145},
    plot56: {x: 90, y: 135.5},
    plot57: {x: 109.2, y: 130},
    plot58: {x: 131, y: 127.3},
    plot59: {x: 158, y: 123},
    plot63: {x: 180.7, y: 126.7},
    plot64: {x: 189.1, y: 125.3},
    plot65: {x: 198.5, y: 123.3},
    plot66: {x: 207, y: 117},
    plot66b: {x: 208, y: 129    },
    plot67: {x: 215.8, y: 119.2},
    plot68: {x: 224, y: 118.7},
    plot69: {x: 232.8, y: 117},
    plot70: {x: 243.1, y: 115.4},
    plot71: {x: 254.4, y: 112.7}
};