//Libraries
import React, { useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
//Utilities
import { getEmptyPerson, stringIsEmpty } from '../../utilities/helper';
import { savePerson } from '../../utilities/peopleRepository';
//Components
import PersonForm from './PersonForm';
//Styles
import '../../styles/form.css';


export default function NewPerson({ waitingList }) {

    const [finalPerson, setFinalPerson] = useState(getEmptyPerson(waitingList));
    const [message, setMessage] = useState("");
    const navigate = useNavigate();
    const context = useOutletContext();
    const ids = context.allPeople.map(p => p.id);

    const getHeading = () => {
        return waitingList ? 'New Waiting List Member' : 'New Plotholder'
    }

    const idTaken = (id) => ids.indexOf(id) !== -1;

    const onSubmit = (personModel) => {
        const id = personModel.id;
        if (stringIsEmpty(personModel.firstName) || stringIsEmpty(personModel.lastName)){
            setMessage(`The person's name must be filled in!`);
            return;
        }
        if (stringIsEmpty(personModel.id)) {
            setMessage(`The person's nickname must be filled in!`);
            return;
        }
        if (idTaken(id)){
            setMessage(`The nickname '${id}' is already in use!`);
            return;
        }
        savePerson(personModel)
        .then(() => {
            navigate(0);
        });
    }

    if (!finalPerson){
        return (
            <div></div>
        );
    }

  return (
    <div className='form-container'>
        <div className='form'>
            <header className='form-header'>{getHeading()}</header>
            <PersonForm initialPerson={finalPerson} waitingList={waitingList} blank={true} onSubmit={onSubmit} /> 
        </div>
        <div className='message'>
            { message }
        </div>
        
    </div>
  )
}
