//Libraries
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
//Utilities
import { getLandDocs } from '../utilities/landRepository';
import { convertAndOrderList, orderAlphabetically } from '../utilities/helper';
//Components
import LandList from '../components/members/LandList';
import Loading from '../components/Loading';
//Styles
import '../styles/land.css';

export default function Land({ guestLevel }) {

  const navigate = useNavigate();
  const [landDocs, setLandDocs] = useState(null); 
  useEffect(() => {
    if (guestLevel < 2){
        navigate('map');
    }
    getLandDocs()
    .then(data => {
      if (data){
      setLandDocs(convertAndOrderList(data, 'dateDescription'));
      }
    });
    },[]);

  if (!landDocs){
    return (<Loading />);
  }

  return (  
    <>
      <h1>Timeline of Land Communications</h1>
      <LandList landDocs={landDocs} />
    </>
  )
}
